import React from 'react';
import './css/typography.css';
import './css/hamburger.css';

const Header = () => {
  const headerStyles = {
    color: 'rgb(0 255 0)',
    backgroundColor: 'black',
    fontFamily: 'Press_Start, Roboto, sans-serif, serif',
    borderBottom: '3px dashed rgb(0 255 0)',
    position: 'absolute',
    width: '100%',
  };

  const navLinkStyle = {
    color: '#0d6efd',
  };

  const blackBackground = {
    backgroundColor: 'black',
    border: '3px dashed rgb(0 255 0)',
  };
  return (
    <nav style={headerStyles} className="header navbar navbar-expand-lg">
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul style={navLinkStyle} className="navbar-nav">
            <li className="nav-item">
              <a className="nes-btn is-primary nav-link active" aria-current="page" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nes-btn is-primary nav-link" href="/about">About</a>
            </li>
            <li className="nav-item dropdown">
              <button className="nes-btn is-primary nav-link dropdown-toggle" type="button" href="#" id="navbarDropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                Listen
                {' '}
                {'>'}
              </button>
              <ul style={blackBackground} className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li><a style={navLinkStyle} className="dropdown-item" href="https://podcasts.apple.com/us/podcast/s-y-mashups/id1543568142">Apple</a></li>
                <li><a style={navLinkStyle} className="dropdown-item" href="https://open.spotify.com/show/0wnr1wjebPLBhoTXrL3WdX?si=cK9q9CGlTS6BFTBoM35b2Q&nd=1">Spotify</a></li>
                <li><a style={navLinkStyle} className="dropdown-item" href="https://podcasts.google.com/feed/aHR0cHM6Ly9tZWRpYS5yc3MuY29tL3NoaXR0eW1hc2h1cHMvZmVlZC54bWw?sa=X&ved=0CAMQ4aUDahcKEwiA35Ou-t_tAhUAAAAAHQAAAAAQAQ&hl=en">Google</a></li>
                <li><a style={navLinkStyle} className="dropdown-item" href="https://media.rss.com/shittymashups/feed.xml">RSS</a></li>
              </ul>
            </li>
            <li className="me-2" style={{ marginLeft: '10px' }}><a style={{ margin: 'auto' }} aria-label="twitter link" href="https://twitter.com/shitty_mashups"><i className="nes-icon twitter is-medium" /></a></li>
            <li className="me-2" style={{ marginLeft: '10px' }}><a style={{ margin: 'auto' }} aria-label="tiktok link" href="https://www.tiktok.com/@shittymashups"><img style={{ width: '48px', imageRendering: 'pixelated' }} src="/tt.png" alt="tiktok icon" /></a></li>
            <li className="me-2" style={{ marginLeft: '10px' }}><a style={{ margin: 'auto' }} aria-label="facebook link" href="https://www.facebook.com/smashupscast"><i className="nes-icon facebook is-medium" /></a></li>
            <li className="me-2" style={{ marginLeft: '10px' }}><a style={{ margin: 'auto' }} aria-label="instagram link" href="https://instagram.com/shittymashups"><i className="nes-icon instagram is-medium" /></a></li>
            <li className="me-2" style={{ marginLeft: '10px' }}><a style={{ margin: 'auto' }} aria-label="gmail link" href="mailto:contact@shittymashups.com"><i className="nes-icon gmail is-medium" /></a></li>
            <li className="me-2" style={{ marginLeft: '10px' }}><a style={{ margin: 'auto' }} aria-label="patreon link" href="https://patreon.com/shittymashups"><i className="nes-icon coin is-medium" /></a></li>
            <li className="m-0 m-lg-auto" style={{ color: 'white' }}>1-670-4-MASHUP</li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
